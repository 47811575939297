// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-tsx": () => import("./../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-methods-tsx": () => import("./../src/pages/lesson-methods.tsx" /* webpackChunkName: "component---src-pages-lesson-methods-tsx" */),
  "component---src-pages-other-activities-tsx": () => import("./../src/pages/other-activities.tsx" /* webpackChunkName: "component---src-pages-other-activities-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-team-tsx": () => import("./../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-useful-links-tsx": () => import("./../src/pages/useful-links.tsx" /* webpackChunkName: "component---src-pages-useful-links-tsx" */)
}

